import PropTypes from "prop-types";
import classNames from "classnames";

import { withStyles } from "@material-ui/core";
import Button from '@material-ui/core/Button';

const styles = (theme) => ({
    playButton: {
      color: theme.palette.common.white,
      borderColor: theme.palette.common.white,
      boxShadow: 'none',
      textTransform: 'none',
      padding: '8px 32px',
      border: '2px solid',
      lineHeight: 1.5,
      backgroundColor: 'transparent',
      borderRadius: "50%",
      height: "90px",
      width: "90px",
      fontSize: "1.25rem",
      margin: (props) =>
        props.gutters ? `0 ${theme.spacing(1)}px` : 0,
      "&:hover": {
        boxShadow: 'none',
        backgroundColor: theme.palette.common.white,
        color: theme.palette.primary.main
      },
      '&:active': {
      },
      '&:focus': {
      },
    },
});

function PlayButton(props) {
  const { classes } = props;

  return (
      <Button onClick={props.onClick} className={classNames(classes.playButton)} disableRipple>
        Play
      </Button>
  );
}

PlayButton.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(PlayButton);
