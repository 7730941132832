import React from 'react';
import PropTypes from "prop-types";
import classNames from "classnames";

import { withStyles } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';

import CopyNoHTML from '../../atoms/CopyNoHTML';
import FooterLink from '../../atoms/FooterLink';

const styles = (theme) => ({
  footerLinkListBox: {
    marginRight: theme.spacing(8),
    "& h2":{
      marginBottom:theme.spacing(1),
    },
  },
});

function FooterLinkList(props) {
  const { classes, footerLinks, title } = props;
  const footerLinkList = footerLinks.map(footerLink =>
    <FooterLink key={footerLink.ID} footerLink={footerLink} />
  );
  return (
    <Box className={classNames(classes.footerLinkListBox)}>
      <Typography variant="body1" component="h2">
        <CopyNoHTML copyNoHTML={title} />
      </Typography>
      <List disablePadding>
        {footerLinkList}
      </List>
    </Box>
  );
}

FooterLinkList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(FooterLinkList);
