import React from 'react';
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core";
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import CopyNoHTML from '../../atoms/CopyNoHTML';

import ProfileItem from '../../molecules/ProfileItem/ProfileItem';

const styles = (theme) => ({
  grid__widthWrapper:{
    paddingTop:theme.spacing(8),
	paddingBottom:theme.spacing(8),
	paddingLeft:0,
	paddingRight:0
  },
  spacingOR:{
    color: theme.palette.text.primary,
    width: `calc(100% + (${theme.spacing(8)}*2px))`,
    margin: `-${theme.spacing(8)}px`,
    "& > .MuiGrid-item": {
      padding: theme.spacing(8),
    },
    [theme.breakpoints.only('sm')]: {
      "& > .MuiGrid-item": {
        padding: theme.spacing(4),
      },
      "& > .MuiGrid-item:last-of-type": {
        paddingTop: 0,
      },
    },
    [theme.breakpoints.down('xs')]: {
      "& > .MuiGrid-item": {
        padding: `${theme.spacing(6)}px ${theme.spacing(2)}px`,
      },
      "& > .MuiGrid-item:last-of-type": {
        paddingTop: 0,
      },
    },
    [theme.breakpoints.down('md')]: {
      "& ul div:first-of-type": {
        borderTop:0,
      },
    },
  },
  standFirst: {
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(12),
    },
  },
  widthFixer: {
	padding: `0 ${theme.spacing(8)}px`,
  },
  [theme.breakpoints.only('sm')]: {
		widthFixer: {
			padding: `0 ${theme.spacing(4)}px`,
		},
  },
  [theme.breakpoints.down('xs')]: {
		widthFixer: {
			padding: `0 ${theme.spacing(2)}px`,
		},
  },
});

function ProfilesFeature({ theme, classes, content: { title, standfirst, profiles }}) {

  const profilesList = profiles.map(profile =>
    <ProfileItem key={profile.id} profile={profile} />
  );

  return (
    <Box>
		<Container maxWidth="xl" className={classes.grid__widthWrapper}>
			<>
				<div className={classes.widthFixer}>
					<Typography variant="subtitle1" component="h2" style={title ? { marginBottom: theme.spacing(2)} : {} }>
						<CopyNoHTML copyNoHTML={title} />
					</Typography>
				</div>
				{profilesList}
			</>
		</Container>
	</Box>
  );
}

ProfilesFeature.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(ProfilesFeature);
