import React from 'react';
import { NavLink } from 'react-router-dom';
import classNames from "classnames";
import PropTypes from 'prop-types';

import { withStyles } from "@material-ui/core";
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const styles = (theme) => ({
  footerLink: {
    padding:0,
    "& div":{
      margin:`${theme.spacing(0.3)}px 0`,
    },
  },
});

function FooterLink(props) {
  const { classes, footerLink: { title, slug, url } } = props;
  if(!slug) return (
    <ListItem component="a" href={url} target="_blank" dense className={classNames(classes.footerLink)}>
      <ListItemText secondary={title} />
    </ListItem>
  );
  return (
    <ListItem component={NavLink} to={`/${slug}`} button className={classNames(classes.footerLink)}>
      <ListItemText secondary={title} />
    </ListItem>
  );
}

FooterLink.propTypes = {
  footerLink: PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
};

export default withStyles(styles, { withTheme: true })(FooterLink);
