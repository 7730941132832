import React from 'react';
import PropTypes from "prop-types";
import classNames from "classnames";

import { withStyles } from "@material-ui/core";
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import CopyNoHTML from '../../atoms/CopyNoHTML';
import CopyWithHTML from '../../atoms/CopyWithHTML';
import StepsButton from '../../atoms/StepsButton';

const styles = (theme) => ({
  grid__widthWrapper:{
    padding:theme.spacing(8),
  },
  spacingOR:{
    width: `calc(100% + (${theme.spacing(8)}*2px))`,
    margin: `-${theme.spacing(8)}px`,
    "& > .MuiGrid-item": {
      padding: theme.spacing(8),
    },
    [theme.breakpoints.only('sm')]: {
      "& > .MuiGrid-item": {
        padding: theme.spacing(4),
      },
      "& > .MuiGrid-item:last-of-type": {
        paddingTop: 0,
      },
    },
    [theme.breakpoints.down('xs')]: {
      "& > .MuiGrid-item": {
        padding: `${theme.spacing(6)}px ${theme.spacing(2)}px`,
      },
      "& > .MuiGrid-item:last-of-type": {
        paddingTop: 0,
      },
    },
  },
  grid__textCol: {
    display:"flex",
    alignItems:"center",
  },
  theImageWrapper: {
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.up('md')]: {
      maxWidth: 400,
    },
  },
  theImage: {
    width: "100%",
    paddingBottom: "100%",
    backgroundImage: (props) => props.content.image.sizes.medium_large ?
      `url(
        ${props.content.image.sizes.medium_large}
      )`: "none",
    backgroundPosition: "center center",
    backgroundSize: "100% auto",
    backgroundRepeat:"no-repeat",
    [theme.breakpoints.only('sm')]: {
      paddingBottom: "56.25%",
    },
    [theme.breakpoints.up('md')]: {
      paddingBottom: "177.78%",
      maxHeight: 800,
    },
  },
  standFirst: {
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(12),
    },
  },
  bodyCopy: {
    "& h6": {
      fontWeight: 400,
      fontSize: "1rem"
    },
  },
});

function ImageFeature({ theme, classes, content: {
    title,
    stand_first,
    body_copy,
    button_type,
    button_text,
    button_link,
    media_position,
}}) {

  const mediaPosition = media_position;
  let rowDirection = "row";
  if(mediaPosition === "left") { rowDirection = "row-reverse" };

  return (
    <Box>
      <Container maxWidth="xl" className={classes.grid__widthWrapper}>
        <Grid
          container
          spacing={10}
          direction={rowDirection}
          classes={{ "spacing-xs-10": classes.spacingOR }}
        >
          <Grid item xs={12} md={6} className={classes.grid__textCol}>

            <div>
              <Typography variant="subtitle1" component="h2" style={title ? { marginBottom:theme.spacing(2)} : {} }>
                <CopyNoHTML copyNoHTML={title} />
              </Typography>

              <Typography variant="h3" gutterBottom className={classNames(classes.standFirst, "standFirst")}>
                <CopyWithHTML copyWithHTML={stand_first} />
              </Typography>

              <Typography variant="body1" component="div" className={classNames(classes.bodyCopy, "bodyCopy")}>
                <CopyWithHTML copyWithHTML={body_copy} />
              </Typography>

              <StepsButton
                buttonType={button_type}
                buttonText={button_text}
                buttonLink={button_link}
                dark white
              />
            </div>

          </Grid>

          <Grid item xs={12} md={6}>
            <div className={classNames(classes.theImageWrapper)}>
              <div className={classes.theImage}></div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

ImageFeature.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(ImageFeature);
