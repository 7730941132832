import React from 'react';
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';

import StepsButton from '../../atoms/StepsButton';

const styles = (theme) => ({
  banner__bannerText: {
    fontSize: 50,
    marginBottom: theme.spacing(2),
    position: "relative",
    marginLeft: "-50%",
    left: "25%",
    [theme.breakpoints.up('sm')]: {
      fontSize: 100,
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 135,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 160,
    },
  },
});

function BannerText(props) {
  return (
    <>
      <Typography variant="h1" component="h2">Stay In Touch</Typography>
      <Typography variant="body1">
        Subscribe to join our growing community of people who are on a journey towards freedom.
      </Typography>
      <br/>
      <StepsButton
        buttonType={"external"}
        buttonText="Subscribe"
        buttonLink="https://mailchi.mp/stepscourse/steps-mailing-list"
        target="_blank"
        dark
        gutters
      />
    </>
  );
}

BannerText.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(BannerText);
