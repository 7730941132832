import React from 'react';
import classNames from "classnames";

import { withStyles } from "@material-ui/core";
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import CopyNoHTML from '../../atoms/CopyNoHTML';
import CopyWithHTML from '../../atoms/CopyWithHTML';
import StepsButton from '../../atoms/StepsButton';
import ImageTwo from '../../atoms/ImageTwo';

const styles = (theme) => ({
  grid__widthWrapper:{
    padding:theme.spacing(8),
  },
  grid__textCol: {
    display:"flex",
    alignItems:"center",
  },
  standFirst: {
    ".productWrapper &": { maxWidth: "60%", marginBottom: "16px" },
  },
  product: {
    display: "flex",
    [theme.breakpoints.down('sm')]: {
      display: "block",
    },
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "32px"
  }
});

function ProductGrid({ theme, classes, content: {
    standfirst,
    product
}}) {

  const products = product.map((product, index) =>
    <Grid item xs={12} md={6} className={classNames(classes.productWrapper, "productWrapper")}>
      <ImageTwo image={product.image} />
      <Box className={classNames(classes.product, "product")}>
        <Typography variant="h3"  className={classNames(classes.standFirst, "standFirst")}>
          <CopyNoHTML copyNoHTML={product.title} />
        </Typography>
        <StepsButton
          buttonType={product.button_type}
          buttonText={product.button_text}
          buttonLink={product.button_link}
          dark white
        />
      </Box>
    </Grid>
  )

  return (
    <Box>
      <Container maxWidth="xl" className={classes.grid__widthWrapper}>
        <Grid
          container
          spacing={10}
          classes={{ "spacing-xs-10": classes.spacingOR }}
        >
          <Grid item xs={12} className={classes.grid__textCol}>
              <Typography variant="h3" gutterBottom className={classNames(classes.standFirst, "standFirst")}>
                <CopyWithHTML copyWithHTML={standfirst} />
              </Typography>
          </Grid>
          {products}
        </Grid>
      </Container>
    </Box>
  );
}

export default withStyles(styles, { withTheme: true })(ProductGrid);
