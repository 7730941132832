import React from 'react';
import PropTypes from "prop-types";
import classNames from "classnames";

import { withStyles } from "@material-ui/core";
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';

import CopyNoHTML from '../../atoms/CopyNoHTML';
import CopyWithHTML from '../../atoms/CopyWithHTML';

const styles = (theme) => ({
  grid__widthWrapper:{
    padding:theme.spacing(8),
  },
  spacingOR:{
    color: (props) =>
      props.content.background_color === "#fff" ?
      theme.palette.primary : theme.palette.common.white,
    width: `calc(100% + (${theme.spacing(8)}*2px))`,
    margin: `-${theme.spacing(8)}px`,
    "& > .MuiGrid-item": {
      padding: theme.spacing(8),
    },
    [theme.breakpoints.only('sm')]: {
      "& > .MuiGrid-item": {
        padding: theme.spacing(4),
      },
      "& > .MuiGrid-item:last-of-type": {
        paddingTop: 0,
      },
    },
    [theme.breakpoints.down('xs')]: {
      "& > .MuiGrid-item": {
        padding: `${theme.spacing(6)}px ${theme.spacing(2)}px`,
      },
      "& > .MuiGrid-item:last-of-type": {
        paddingTop: 0,
      },
    },
  },
});

function FullWidthStandFirstFeature({ theme, classes, content: {
    title,
    stand_first,
    body_copy,
    background_color
}}) {

  return (
    <Box bgcolor={background_color}>
      <Container maxWidth="xl" className={classes.grid__widthWrapper}>
        <Grid
          container
          spacing={10}
          direction="row-reverse"
          classes={{ "spacing-xs-10": classes.spacingOR }}
        >
          <Grid item xs={12}>

            <Typography variant="subtitle1" component="h2" style={title ? { marginBottom: theme.spacing(2)} : {} }>
              <CopyNoHTML copyNoHTML={title} />
            </Typography>

            <Typography variant="h3" className={classNames("standFirst")}>
              <CopyWithHTML copyWithHTML={stand_first} />
            </Typography>

          </Grid>

          <Grid item xs={12} md={9} style={{ paddingTop:0}}>
            <Typography variant="body1" component="div" gutterBottom>
              <CopyWithHTML copyWithHTML={body_copy} />
            </Typography>
          </Grid>
          <Hidden smDown>
            <Grid item xs={12} md={3} style={{ paddingTop:0}}>
            </Grid>
          </Hidden>

        </Grid>
      </Container>
    </Box>
  );
}

FullWidthStandFirstFeature.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(FullWidthStandFirstFeature);
