import React from 'react';
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core";

const styles = (theme) => ({
  image__coverImage: {
    height:"100vh",
    paddingBottom: "100%",
    backgroundImage: (props) => props.image.sizes.large ?
      `url(
        ${props.image.sizes.large}
      )`: "none",
    backgroundPosition: "center right",
    backgroundSize: "auto 100%",
    backgroundRepeat: "no-repeat",
  },
});

function FullScreenImage({ classes }) {
  return (
    <div className={classes.image__coverImage}></div>
  );
}

FullScreenImage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(FullScreenImage);
