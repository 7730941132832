import React from 'react';
import classNames from "classnames";
import PropTypes from 'prop-types';

import { withStyles } from "@material-ui/core";
import List from '@material-ui/core/List';

import HeaderLink from '../../atoms/HeaderLink';

const styles = (theme) => ({
  headerLinkListDT: {
    display: "flex",
    justifyContent: "center",
    padding:0,
    fontSize: theme.typography.body2.fontSize,
    height: theme.spacing(8),
    "&:last-of-type": {
      justifyContent: "flex-end",
    },
    "& > div": {
      width: "fit-content",
      padding: 0,
    },
    "& div a": {
      height: "100%",
    },
    "& div a div": {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      height: "100%",
      padding: `${theme.spacing(2.6)}px 0px`,
    },
    "& div a:hover": {
      textDecoration:"none",
    },
    "& div a.active div": {
      borderBottom: `4px solid ${theme.palette.text.primary}`
    }
  },
  headerLinkListMobile: {
    "& a": {
      width: "100%",
    },
    "& a:hover": {
      textDecoration: "none",
    },
  },
});

function HeaderLinkList(props) {
  const { classes, headerLinks, divider, disableGutters, mobile, onClick } = props;
  const headerLinkList = headerLinks.map(headerLink =>
    <HeaderLink key={headerLink.ID} headerLink={headerLink} disableGutters={disableGutters} divider={divider} onClick={onClick} />
  );
  return (
    <List className={classNames(mobile ? classes.headerLinkListMobile : classes.headerLinkListDT)}>
      {headerLinkList}
    </List>
  );
}

HeaderLinkList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(HeaderLinkList);
