import React from 'react';
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
import classNames from "classnames";

import { withStyles } from "@material-ui/core";
import Button from '@material-ui/core/Button';

const styles = (theme) => ({
    stepsButton: {
      color: (props) =>
        props.dark ? theme.palette.primary.main : theme.palette.common.white,
      borderColor: (props) =>
        props.dark ? theme.palette.primary.main : theme.palette.common.white,
      boxShadow: 'none',
      textTransform: 'none',
      fontSize: 16,
      padding: '8px 32px',
      border: '1px solid',
      lineHeight: 1.5,
      backgroundColor: 'transparent',
      borderRadius: theme.spacing(3),
      margin: (props) =>
        props.gutters ? `0 ${theme.spacing(1)}px` : 0,
      '&:hover': {
        backgroundColor: (props) =>
          props.white ? theme.palette.primary.main : theme.palette.common.white,
        borderColor: (props) =>
          props.white ? theme.palette.primary.main : theme.palette.common.white,
        color: (props) =>
          props.white ? theme.palette.common.white : theme.palette.primary.main,
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: '#0062cc',
        borderColor: '#005cbf',
      },
      '&:focus': {
      },
    },
});

function StepsButton(props) {
  const { classes, buttonType, buttonText, buttonLink } = props;

  let slug = null;

  if(buttonType === "internal"){
    slug = buttonLink.split('/');
    slug = slug[slug.length -2];
  }

  if (buttonType === "internal") {
    return (
      <Button
        variant="contained"
        className={classNames(classes.stepsButton)}
        component={Link}
        to={`/${slug}`}
      >
        {buttonText}
      </Button>
    );
  } else if (buttonType === "external") {
    return (
      <Button
        variant="contained"
        className={classNames(classes.stepsButton)}
        href={buttonLink}
        target="_blank"
      >
        {buttonText}
      </Button>
    );
  } else return null;
}

StepsButton.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(StepsButton);
