import React from 'react';
import PropTypes from "prop-types";
import classNames from "classnames";

import { withStyles } from "@material-ui/core";
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import CopyNoHTML from '../../atoms/CopyNoHTML';
import CopyWithHTML from '../../atoms/CopyWithHTML';
import Video from '../../molecules/Video';
import Image from '../../atoms/Image';
import StepsButton from '../../atoms/StepsButton';

const styles = (theme) => ({
  grid__widthWrapper:{
    padding:theme.spacing(8),
  },
  spacingOR:{
    color: (props) => props.content.background_colour === "#89CCED" ?
      theme.palette.text.primary : theme.palette.text.secondary,
    width: `calc(100% + (${theme.spacing(8)}*2px))`,
    margin: `-${theme.spacing(8)}px`,
    "& > .MuiGrid-item": {
      padding: theme.spacing(8),
    },
    [theme.breakpoints.only('sm')]: {
      "& > .MuiGrid-item": {
        padding: theme.spacing(4),
      },
      "& > .MuiGrid-item:last-of-type": {
        paddingTop: 0,
      },
    },
    [theme.breakpoints.down('xs')]: {
      "& > .MuiGrid-item": {
        padding: `${theme.spacing(6)}px ${theme.spacing(2)}px`,
      },
      "& > .MuiGrid-item:last-of-type": {
        paddingTop: 0,
      },
    },
  },
  standFirst: {
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(12),
    },
  },
});

function StickyFeature({ theme, classes, content: {
    title,
    stand_first,
    body_copy,
    button_button_type,
    button_button_text,
    button_button_link,
    media_position,
    media_type,
    video_title,
    video_cover,
    video,
    image,
    background_colour
}}) {

  const mediaPosition = media_position;
  let rowDirection = "row";
  if(mediaPosition === "left") { rowDirection = "row-reverse" };

  return (
    <Box bgcolor={background_colour}>
      <Container maxWidth="xl" className={classes.grid__widthWrapper}>
        <Grid
          container
          spacing={10}
          direction={rowDirection}
          classes={{ "spacing-xs-10": classes.spacingOR }}
        >
          <Grid item xs={12} md={6}>

            <Typography variant="subtitle1" component="h2" style={title ? { marginBottom: theme.spacing(2)} : {} }>
              <CopyNoHTML copyNoHTML={title} />
            </Typography>

            <Typography variant="h3" gutterBottom className={classNames(classes.standFirst, "standFirst")}>
              <CopyWithHTML copyWithHTML={stand_first} />
            </Typography>

            <Typography variant="body1" component="div">
              <CopyWithHTML copyWithHTML={body_copy} />
            </Typography>

            <StepsButton
              dark={background_colour === "#89CCED" ? true : false}
              buttonType={button_button_type}
              buttonText={button_button_text}
              buttonLink={button_button_link}
            />

          </Grid>

          <Grid item xs={12} md={6}>
            {media_type === "video"
              ? <Video videoTitle={video_title} videoCover={video_cover} video={video} />
              : <Image image={image} />
            }
          </Grid>

        </Grid>
      </Container>
    </Box>
  );
}

StickyFeature.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(StickyFeature);
