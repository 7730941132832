import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import classNames from "classnames";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Drawer from '@material-ui/core/Drawer';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';

import HideOnScroll from '../../atoms/HideOnScroll';
import SiteLogo from '../../atoms/SiteLogo';
import HeaderLinkList from '../../molecules/HeaderLinkList';

const styles = (theme) => ({
  header__toolBar: {
    justifyContent: "space-between",
    "& > a, & > ul": {
      width:"22.5%",
    },
    "& > ul:first-of-type": {
      width:"65%",
    },
    "&.hideContent a, &.hideContent button":{
      transform:"translate(0,50px)",
      opacity: "0",
    },
    "&.showContent a, &.showContent button":{
      opacity: "1",
      transform:"translate(0,0px)",
      transition: "opacity 0.35s cubic-bezier(0.550, 0.055, 0.675, 0.190), transform 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000)",
    },
    "&.showContent ul div:nth-of-type(1) a": {
      transitionDelay:"0.15s",
    },
    "&.showContent ul div:nth-of-type(2) a, &.showContent button": {
      transitionDelay:"0.2s",
    },
    "&.showContent ul div:nth-of-type(3) a": {
      transitionDelay:"0.25s",
    },
    "&.showContent ul div:nth-of-type(4) a": {
      transitionDelay:"0.3s",
    },
    "&.showContent ul div:nth-of-type(5) a": {
      transitionDelay:"0.35s",
    },
    "&.showContent ul div:nth-of-type(6) a": {
      transitionDelay:"0.4s",
    },
    "&.showContent ul div:nth-of-type(7) a": {
      transitionDelay:"0.45s",
    },
    "&.showContent ul:nth-of-type(2) a": {
      transitionDelay:"0.5s!important",
    },
  },
  mob__appBar:{
    backgroundColor:theme.palette.primary.main,
  },
  drawerPaper: {
    height: "100%",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.secondary,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    display: "flex",
    flexDirection:"column",
    justifyContent:"space-between",
    "& a, & button": {
      color: theme.palette.text.secondary,
      textDecoration: "none",
      fontFamily: theme.typography.h1.fontFamily,
      fontSize: theme.typography.h3.fontSize,
      lineHight: theme.typography.h3.lineHeight,
    },
  },
  drawer__close: {
    position: "absolute",
    right: theme.spacing(0.5),
    cursor: "pointer",
  },
});

function Header(props) {
  const { classes } = props;

  const [siteLogoState, setSiteLogoState] = useState({
    loading: true,
    siteLogo: [
      {
        acf: {
          header: {
            site_logo: {
              title: "Site Logo",
              sizes: "",
            },
            site_logo_alt: {
              title: "Site Logo Alt",
              sizes: "",
            },
          },
        },
      },
    ],
  });

  const loadSiteLogo = useCallback(() => {
    setSiteLogoState(prevState => {
      return {...prevState, loading: true}
     });
    const apiUrl = `${process.env.REACT_APP_API_URL}//wp-json/wp/v2/header_and_footer`;
    axios.get(apiUrl)
      .then((response) => {
        const siteLogoData = response.data;
        setSiteLogoState({ loading: false, siteLogo: siteLogoData });
      })
      .catch(() => {
        console.log("No data loaded!")
      });
  }, []);

  useEffect(() => {
    loadSiteLogo();
  }, [loadSiteLogo]);

  const [navMenuState, setNavMenuState] = useState({
    loading: true,
    navMenu: {
      items: [
        {
          "ID": 0,
          "title": "",
          "url": "",
        },
      ],
    },
  });

  const loadNavMenu = useCallback(() => {
    setNavMenuState(prevState => {
      return {...prevState, loading: true}
     });
    const apiUrl = `${process.env.REACT_APP_API_URL}//wp-json/menus/v1/menus/steps-header-site-navigation`;
    axios.get(apiUrl)
      .then((response) => {
        const navMenuData = response.data;
        setNavMenuState({ loading: false, navMenu: navMenuData });
      })
      .catch(() => {
        console.log("No data loaded!")
      });
  }, []);

  useEffect(() => {
    loadNavMenu();
  }, [loadNavMenu]);

  const [accountMenuState, setAccountMenuState] = useState({
    loading: true,
    accountMenu: {
      items: [
        {
          "ID": 0,
          "title": "",
          "url": "",
        },
      ],
    },
  });

  const loadAccountMenu = useCallback(() => {
    setAccountMenuState(prevState => {
      return {...prevState, loading: true}
     });
    const apiUrl = `${process.env.REACT_APP_API_URL}//wp-json/menus/v1/menus/steps-header-account`;
    axios.get(apiUrl)
      .then((response) => {
        const accountMenuData = response.data;
        setAccountMenuState({ loading: false, accountMenu: accountMenuData });
      })
      .catch(() => {
        console.log("No data loaded!")
      });
  }, []);

  useEffect(() => {
    loadAccountMenu();
  }, [loadAccountMenu]);

  const [drawerState, setDrawerState] = useState({
    drawerOpen: false
  });
  const toggleDrawer = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerState({ ...drawerState, drawerOpen: open });
  };


  let headerReady = false;
  if(!siteLogoState.loading && !navMenuState.loading && !accountMenuState.loading) {
    headerReady = true
  }
  const [headerState, setHeaderState] = useState({
    headerReady: false
  })
  useEffect(() => {
    if(headerReady) setHeaderState({ headerReady:true});
  }, [headerReady]);

  return (
    <>
      <HideOnScroll {...props}>
        <AppBar color="default" elevation={0} classes={{ root: classes.appBar }}>
          <Toolbar
            className={classNames(classes.header__toolBar, headerState.headerReady ? "showContent" : "hideContent")} >

            <SiteLogo siteLogo={siteLogoState.siteLogo[0].acf.header.site_logo} />
            <Hidden mdDown>
              <HeaderLinkList headerLinks={navMenuState.navMenu.items} disableGutters />
              <HeaderLinkList headerLinks={accountMenuState.accountMenu.items} disableGutters />
            </Hidden>

            <Hidden lgUp>
              <IconButton edge="end" color="primary" onClick={toggleDrawer(true)}>
                <MenuIcon />
              </IconButton>
            </Hidden>

          </Toolbar>
        </AppBar>
      </HideOnScroll>

      <Toolbar id="back-to-top-anchor" />

      <Drawer
        anchor="top"
        open={drawerState.drawerOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        transitionDuration={0.0}
        className={classNames(classes.drawer)}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <AppBar color="default" elevation={0} classes={{ root: classes.mob__appBar }}>
          <Toolbar className={classNames(classes.header__toolBar)}>
            <SiteLogo siteLogo={siteLogoState.siteLogo[0].acf.header.site_logo_alt} onClick={toggleDrawer(false)} />
            <IconButton end="edge" className={classNames(classes.drawer__close)} onClick={toggleDrawer(false)}>
              <CloseIcon  />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div style={{ height: "56px" }}/>
        <HeaderLinkList
          headerLinks={navMenuState.navMenu.items}
          onClick={toggleDrawer(false)}
          mobile
          disableGutters
        />
        <HeaderLinkList
          headerLinks={accountMenuState.accountMenu.items}
          onClick={toggleDrawer(false)}
          disableGutters
          mobile
        />
      </Drawer>
    </>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Header);
