import React from 'react';

import Box from '@material-ui/core/Box';

import Carousel from '../../molecules/Carousel';
import SlideV2 from '../../atoms/SlideV2';

function CarouselFeatureV2({
  content: {
    title,
    slide,
    background_colour,
  },
  slider,
  theme
}) {
  const slideList = slide.map((slide, index) =>
    <SlideV2 key={slide.id} slide={slide} color={background_colour} />
  );

  return (
    <Box bgcolor={background_colour}>
      <Carousel bgColor={background_colour} background_colour={background_colour} title={title}>
        {slideList}
      </Carousel>
    </Box>
  );
}

export default CarouselFeatureV2
