import React, { useState, useEffect, createRef } from 'react';
import classNames from "classnames";
import PropTypes from "prop-types";
import Slider from "react-slick";

import { withStyles } from "@material-ui/core";
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

import { ReactComponent as ArrowLeft } from '../../../assets/ArrowLeft.svg';
import { ReactComponent as ArrowRight } from '../../../assets/ArrowRight.svg';
import CopyWithHTML from '../../atoms/CopyWithHTML';
import Slide from '../../molecules/Slide';

const styles = (theme) => ({
  grid__widthWrapper:{
    padding:theme.spacing(8),
  },
  spacingOR:{
    color: (props) => props.content.background_colour === "#03172E" ?
      theme.palette.common.white : theme.palette.text.primary,
    width: `calc(100% + (${theme.spacing(8)}*2px))`,
    margin: `-${theme.spacing(8)}px`,
    "& > .MuiGrid-item": {
      padding: theme.spacing(8),
    },
    [theme.breakpoints.down('sm')]: {
      "& > .MuiGrid-item": {
        padding: theme.spacing(4),
        paddingBottom: theme.spacing(2),
      },
      "& > .MuiGrid-item:last-of-type": {
        paddingTop: 0,
        paddingBottom: theme.spacing(2),
      },
    },
    [theme.breakpoints.down('xs')]: {
      "& > .MuiGrid-item": {
        padding: `${theme.spacing(6)}px ${theme.spacing(2)}px`,
        paddingBottom: theme.spacing(2),
      },
      "& > .MuiGrid-item:last-of-type": {
        paddingTop: 0,
        paddingBottom: theme.spacing(1),
      },
    },
  },
  carousel__copy: {
    "& p": {
      marginBottom:0,
    },
  },
  carousel__nav: {
    display:"flex",
    alignItems: "center",
    justifyContent:"space-between",
    fontSize: theme.typography.h3.fontSize,
    lineHeight: theme.typography.h3.lineHeight,
    [theme.breakpoints.up('md')]: {
      justifyContent:"flex-end",
    },
    "& svg": {
      width: theme.spacing(4),
      cursor: "pointer",
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(2),
      fill: (props) => props.content.background_colour === "#03172E" ?
        theme.palette.common.white : theme.palette.text.primary,
    },
    "& em": {
      display:"block",
      marginTop:"6px",
      marginBottom:"-2px",
    },
  },
  carousel__wrapper: {
    paddingBottom: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(6),
      "& > .MuiGrid-item": {
        paddingBottom: theme.spacing(4),
      },
    },
  },
});

let firstClientX, clientX;

const preventTouch = e => {
  const minValue = 5; // threshold

  clientX = e.touches[0].clientX - firstClientX;

  // Vertical scrolling does not work when you start swiping horizontally.
  if (Math.abs(clientX) > minValue) {
    e.preventDefault();
    e.returnValue = false;

    return false;
  }
};

const touchStart = e => {
  firstClientX = e.touches[0].clientX;
};

function CarouselFeature({
  classes,
  content: {
    title,
    slide,
    background_colour,
  },
  slider,
  theme
}) {
  const slideList = slide.map(slide =>
    <Slide key={slide.id} slide={slide} color={background_colour} />
  );

  const [carouselState, setCarouselState] = useState({
    currentSlide: 0,
  });

  function handleAfterChange(index) {
    setCarouselState({
      currentSlide: index,
    });
  };

  const slickSettings = {
    draggable: false,
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    swipe: true,
    slidesToScroll: 1,
    swipeToSlide: true,
    touchMove: true,
    centerMode: true,
    centerPadding: "100px",
    variableWidth: true,
    responsive: [
      {
         breakpoint: 960,
         settings: {
           centerMode: false,
           variableWidth: true,
           infinite: true,
         },
      },
      {
         breakpoint: 600,
         settings: {
           centerMode: false,
           variableWidth: true,
         },
      },
    ],
    afterChange: handleAfterChange,
  };

  const previous = () => slider.slickPrev();
  const next = () => slider.slickNext();

  let containerRef = createRef();

  useEffect(() => {
    let containerRefCurrent = containerRef.current;
    if (containerRefCurrent) {
      containerRefCurrent.addEventListener("touchstart", touchStart);
      containerRefCurrent.addEventListener("touchmove", preventTouch, {
        passive: false
      });
    }

    return () => {
      if (containerRefCurrent) {
        containerRefCurrent.removeEventListener("touchstart", touchStart);
        containerRefCurrent.removeEventListener("touchmove", preventTouch, {
          passive: false
        });
      }
    };
  });

  return (
    <Box bgcolor={background_colour}>
      <Container maxWidth="xl" className={classes.grid__widthWrapper}>
        <Grid
          container
          spacing={10}
          classes={{ "spacing-xs-10": classes.spacingOR }}
        >
          <Grid item xs={12} md={9}>

            <Typography variant="h3" className={classNames(classes.carousel__copy, "standFirst")}>
              <CopyWithHTML copyWithHTML={title} />
            </Typography>

          </Grid>

          <Grid item className={classNames(classes.carousel__nav)} xs={12} md={3}>
            <div>
              <ArrowLeft onClick={previous} />
              <ArrowRight onClick={next} />
            </div>
            <Hidden mdUp>
              <Typography variant="body1" component="div">
                <em>{ carouselState.currentSlide+1 + '/' + slideList.length }</em>
              </Typography>
            </Hidden>
          </Grid>

        </Grid>
      </Container>

      <div
        className={classNames(classes.carousel__wrapper)}
        ref={containerRef}
      >
        <Slider ref={c => (slider = c)} {...slickSettings}>
          {slideList}
        </Slider>
      </div>
    </Box>
  );
}

CarouselFeature.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(CarouselFeature);
