import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import classNames from "classnames";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import FooterLinkList from '../../molecules/FooterLinkList';
import Copyright from '../../atoms/CopyRight';
import SocialLinkList from '../../molecules/SocialLinkList';

const styles = (theme) => ({
  footer: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    justifyContent: "space-between",
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4),
    },
  },
});

function Footer(props) {
  const { classes, theme } = props;

  const [footerState, setFooterState] = useState({
    loading: true,
    footer: [
      {
        acf: {
          footer: {
            copyright: "",
            social_links: [
              {
                "id": "1",
                "social_link": "",
                "url": "",
              },
            ],
          },
        },
      },
    ],
  });
  const loadFooter = useCallback(() => {
    setFooterState(prevState => {
      return {...prevState, loading: true}
    });
    const apiUrl = `${process.env.REACT_APP_API_URL}//wp-json/wp/v2/header_and_footer`;
    axios.get(apiUrl)
      .then((response) => {
        const footerData = response.data;
        setFooterState({ loading: false, footer: footerData });
      })
      .catch(() => {
        console.log("No data loaded!")
      });
  }, []);

  useEffect(() => {
    loadFooter();
  }, [loadFooter]);

  const [resourcesMenuState, setResourcesMenuState] = useState({
    loading: true,
    resourcesMenu: {
      items: [
        {
          "ID": 0,
          "title": "",
          "url": "",
        },
      ],
    },
  });

  const loadResourcesMenu = useCallback(() => {
    setResourcesMenuState(prevState => {
      return {...prevState, loading: true}
     });
    const apiUrl = `${process.env.REACT_APP_API_URL}//wp-json/menus/v1/menus/steps-footer-one`;
    axios.get(apiUrl)
      .then((response) => {
        const resourcesMenuData = response.data;
        setResourcesMenuState({ loading: false, resourcesMenu: resourcesMenuData });
      })
      .catch(() => {
        console.log("No data loaded!")
      });
  }, []);

  useEffect(() => {
    loadResourcesMenu();
  }, [loadResourcesMenu]);

  const [policiesMenuState, setPoliciesMenuState] = useState({
    loading: true,
    policiesMenu: {
      items: [
        {
          "ID": 0,
          "title": "",
          "url": "",
        },
      ],
    },
  });

  const loadPoliciesMenu = useCallback(() => {
    setPoliciesMenuState(prevState => {
      return {...prevState, loading: true}
     });
    const apiUrl = `${process.env.REACT_APP_API_URL}//wp-json/menus/v1/menus/steps-footer-policies`;
    axios.get(apiUrl)
      .then((response) => {
        const policiesMenuData = response.data;
        setPoliciesMenuState({ loading: false, policiesMenu: policiesMenuData });
      })
      .catch(() => {
        console.log("No data loaded!")
      });
  }, []);

  useEffect(() => {
    loadPoliciesMenu();
  }, [loadPoliciesMenu]);

  // isLoading={resourcesMenuState.loading}
  // isLoading={policiesMenuState.loading}

  return (
    <Grid container direction="row-reverse" className={classNames(classes.footer)}>

      <Grid item xs={12} md={4} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
        <SocialLinkList socialLinks={footerState.footer[0].acf.footer.social_links} />
      </Grid>

      <Grid item xs={12} md={7}>
        <Grid container style={{ marginBottom:theme.spacing(8) }}>
          <Grid item>
            <FooterLinkList
              footerLinks={resourcesMenuState.resourcesMenu.items}
              title="Resources"
            />
          </Grid>
          <Grid item>
            <FooterLinkList
              footerLinks={policiesMenuState.policiesMenu.items}
              title="Policies"
            />
          </Grid>
        </Grid>
        <Typography variant="body2" component="div">
          <Copyright copyright={footerState.footer[0].acf.footer.copyright} />
        </Typography>
		<br/>
		<img
			style={{height:"32px"}}
			src="/fundraising_badge.png"
			alt=""
		/>
      </Grid>

    </Grid>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Footer);
