import React from 'react';
import PropTypes from 'prop-types';
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollTop(props) {
  const { children } = props;

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const { pathname } = useLocation();
  useEffect(() => {
    document.querySelector('#back-to-top-anchor').scrollIntoView({ behavior: 'instant', block: 'start' })
  }, [pathname]);

  return (
    <div onClick={handleClick} role="presentation">
      {children}
    </div>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ScrollTop;
