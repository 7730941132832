import React from 'react';
import PropTypes from "prop-types";
import classNames from "classnames";

import { withStyles } from "@material-ui/core";

const styles = (theme) => ({
  image__cover: {
    position:"relative",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.up('md')]: {
      maxWidth: 800,
    },
  },
  image__coverImage: {
    width: "100%",
    paddingBottom: "100%",
    backgroundImage: (props) => props.image.sizes.large ?
      `url(
        ${props.image.sizes.large}
      )`: "none",
    backgroundPosition: "center center",
    // ".productWrapper &": {
    //   backgroundSize: "inherit",
    // },
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.only('sm')]: {
      paddingBottom: "56.25%",
    },
  },
});

function Image({ classes }) {
  return (
    <div className={classes.image__cover}>
      <div className={classNames(classes.image__coverImage)} />
    </div>
  );
}

Image.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Image);
