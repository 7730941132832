import React from 'react';
import PropTypes from "prop-types";
import classNames from "classnames";

import { withStyles } from "@material-ui/core";
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';

import CopyWithHTML from '../../atoms/CopyWithHTML';
import FullScreenImage from '../../atoms/FullScreenImage';
import FullScreenVideo from '../../atoms/FullScreenVideo';

const styles = (theme) => ({
  heroBox: {
    position: "relative",
    marginTop:"-3.5rem",
    [theme.breakpoints.up('sm')]: {
      height:"calc((100vw)/(16/9))",
      marginTop:"0",
    },
    [theme.breakpoints.up('md')]: {
    },
  },
  heroCopy__wrapper: {
    top: "0",
    width: "100%",
    height: "50vh",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      position: "absolute",
      height: "100%",
      padding: theme.spacing(8),
    }
  },
  heroCopy: {
    color:theme.palette.primary.main,
    marginTop: -theme.spacing(0),
    [theme.breakpoints.up('sm')]: {
      color:theme.palette.common.white,
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: -theme.spacing(8),
      width: "50%",
      "& p": {
        marginTop: 0,
        marginBottom: 0,
      },
    },
    [theme.breakpoints.down('xs')]: {
      marginTop:"3.5rem"
    },
  },
});

function Hero({ classes, content: {
  media_type,
  video_title,
  video_cover,
  video,
  image,
  body_copy,
  body_copy_mobile
}}) {
  if(media_type === "image"){
    return (
      <Box className={classNames(classes.heroBox)}>
        <FullScreenImage image={video_cover} />
        <Box className={classNames(classes.heroCopy__wrapper)} >
          <Typography variant="h3" component="h1" className={classNames(classes.heroCopy, "standFirst")}>
            <Hidden smUp>
              <CopyWithHTML copyWithHTML={body_copy_mobile} />
            </Hidden>
            <Hidden xsDown>
              <CopyWithHTML copyWithHTML={body_copy} />
            </Hidden>
          </Typography>
        </Box>
      </Box>
    );
  };
  return (
    <Box className={classNames(classes.heroBox)}>
      <Box className={classNames(classes.heroCopy__wrapper)} >
        <Typography variant="h3" component="h1" color="textSecondary" className={classNames(classes.heroCopy, "standFirst")}>
          <Hidden mdUp>
            <CopyWithHTML copyWithHTML={body_copy_mobile} />
          </Hidden>
          <Hidden smDown>
            <CopyWithHTML copyWithHTML={body_copy} />
          </Hidden>
        </Typography>
      </Box>
      <FullScreenVideo videoTitle={video_title} video={video} />
    </Box>
  );
}

Hero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Hero);
