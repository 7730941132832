import React from 'react';
import PropTypes from 'prop-types';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { IconContext } from "react-icons";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";

function SocialLink(props) {
  const { socialLink: { social_link, url } } = props;
  return (
    <ListItem component="a" href={url} target="_blank" dense disableGutters>
      <ListItemIcon>
        <IconContext.Provider
          value={{ color: 'white', size: '1.5rem', verticalAlign: 'top'}}
        >
          {social_link === "facebook" &&
            <FaFacebook />
          }
          {social_link === "instagram" &&
            <FaInstagram />
          }
          {social_link === "twitter" &&
            <FaTwitter />
          }
        </IconContext.Provider>
      </ListItemIcon>
    </ListItem>
  );
}

SocialLink.propTypes = {
  socialLink: PropTypes.shape({
    social_link: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
};

export default SocialLink;
