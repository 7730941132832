import React from 'react';
import PropTypes from "prop-types";
import classNames from "classnames";

import { withStyles } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import CopyNoHTML from '../../atoms/CopyNoHTML';
import CopyWithHTML from '../../atoms/CopyWithHTML';
import StepsButton from '../../atoms/StepsButton';

const styles = (theme) => ({
  grid__step: {
    textAlign: "center",
    margin: `${theme.spacing(4)}px 0`
  },
  step: {
    backgroundColor: "#153A7C",
    borderRadius: "50%",
    width: theme.spacing(8),
    height: theme.spacing(8),
    color: theme.palette.common.white,
    fontSize: theme.typography.h3.fontSize,
    fontFamily: theme.typography.h3.fontFamily,
    paddingTop: "11px",
    marginBottom: theme.spacing(4),
    "&:hover":{
      backgroundColor: "#153A7C",
      cursor: "default",
    },
  },
  standFirst: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(2),
    },
  },
  bodyCopy: {
    marginBottom: theme.spacing(4),
  },
})

function Step({ theme, classes, index, steps, step: {
  title,
  body_copy,
  button_type,
  button_text,
  button_link,
} }) {
  return (

    <Grid item xs={12} md={parseInt(steps)} className={classes.grid__step}>
        <Button className={classes.step}>
          { index + 1 }
        </Button>

        <Typography variant="h3" gutterBottom className={classNames(classes.standFirst, "standFirst")}>
          <CopyNoHTML copyNoHTML={title} />
        </Typography>

        <Typography variant="body1" className={classNames(classes.bodyCopy)}>
          <CopyWithHTML copyWithHTML={body_copy} />
        </Typography>

        <StepsButton
          buttonType={button_type}
          buttonText={button_text}
          buttonLink={button_link}
          dark white
        />

        <br/>
    </Grid>
  )
}

Step.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Step);
