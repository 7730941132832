import React, { useRef }  from 'react'
import PropTypes from "prop-types";
import classNames from "classnames";

import { withStyles } from "@material-ui/core";
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

import { usePosition } from '../../atoms/UsePosition';
import { ReactComponent as ArrowLeft } from '../../../assets/ArrowLeft.svg';
import { ReactComponent as ArrowRight } from '../../../assets/ArrowRight.svg';
import CopyWithHTML from '../../atoms/CopyWithHTML';

const styles = (theme) => ({
  grid__widthWrapper:{
    padding:theme.spacing(8),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding:theme.spacing(8),
    },
  },
  spacingOR:{
    color: (props) => props.background_colour === "#03172E" ?
      theme.palette.common.white : theme.palette.text.primary,
    width: `calc(100% + (${theme.spacing(8)}*2px))`,
    margin: `-${theme.spacing(8)}px`,
    "& > .MuiGrid-item": {
      padding: theme.spacing(8),
    },
    [theme.breakpoints.down('sm')]: {
      "& > .MuiGrid-item": {
        padding: theme.spacing(4),
        paddingBottom: theme.spacing(2),
      },
      "& > .MuiGrid-item:last-of-type": {
        paddingTop: 0,
        paddingBottom: theme.spacing(2),
      },
    },
    [theme.breakpoints.down('xs')]: {
      "& > .MuiGrid-item": {
        padding: `${theme.spacing(6)}px ${theme.spacing(2)}px`,
        paddingBottom: theme.spacing(2),
      },
      "& > .MuiGrid-item:last-of-type": {
        paddingTop: 0,
        paddingBottom: theme.spacing(1),
      },
    },
  },
  carousel__copy: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(8),
    [theme.breakpoints.up('md')]: {
      marginBottom:0,
    },
    "& p": {
      marginBottom:theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        marginBottom:0,
      },
    },
  },
  carousel__nav: {
    display:"flex",
    alignItems: "center",
    justifyContent:"space-between",
    fontSize: theme.typography.h3.fontSize,
    lineHeight: theme.typography.h3.lineHeight,
    [theme.breakpoints.up('md')]: {
      justifyContent:"flex-end",
    },
    "& svg": {
      width: theme.spacing(4),
      cursor: "pointer",
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(2),
      fill: (props) => props.background_colour === "#03172E" ?
        theme.palette.common.white : theme.palette.text.primary,
    },
    "& em": {
      display:"block",
      marginTop:"6px",
      marginBottom:"-2px",
    },
  },
  carousel__wrapper: {
    paddingBottom: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(6),
      "& > .MuiGrid-item": {
        paddingBottom: theme.spacing(4),
      },
    },
  },
  carousel__container:{
    overflow: "hidden",
    position: "relative",
    maxWidth: "100%",

    "& svg": {
      width: theme.spacing(4),
      cursor: "pointer",
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(2),
      fill: (props) => props.bgColor === "#03172E" ?
        theme.palette.common.white : theme.palette.text.primary,
    },
  },
  carousel__inner:{
    display: "flex",
    overflowX: "scroll",
    scrollSnapType: "x mandatory",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    //marginLeft: "-1rem",

    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  carousel__item:{
    "&":{
      scrollSnapAlign: "center",
    },
  },
})

function Carousel({
  noSlides,
  title,
  background_colour,
  children,
  classes,
}){
  const ref = useRef()

  const { scrollRight, scrollLeft } = usePosition(ref)

  return (
    <>
      <Container maxWidth="xl" className={classes.grid__widthWrapper}>
        <Grid
          container
          spacing={10}
          classes={{ "spacing-xs-10": classes.spacingOR }}
        >
          <Grid item xs={12} md={9}>

            <Typography variant="h3" className={classNames(classes.carousel__copy, "standFirst")}>
              <CopyWithHTML copyWithHTML={title} />
            </Typography>

          </Grid>

          <Hidden smDown>
            <Grid item className={classNames(classes.carousel__nav)} xs={12} md={3}>
              <div>
              <ArrowLeft
                onClick={scrollLeft}
                aria-label="Previous slide"
              />
              <ArrowRight
                onClick={scrollRight}
                aria-label="Next slide"
               />
              </div>
            </Grid>
          </Hidden>

        </Grid>
      </Container>

      <div
        className={classNames(classes.carousel__wrapper)}
      >

        <Box className={classNames(classes.carousel__container)} role="region" aria-label="Colors carousel">
          <Box className={classNames(classes.carousel__inner)} ref={ref}>
            {React.Children.map(children, (child, index) => (
              <Box className={classNames(classes.carousel__item, 'item_'+(index+1))} key={index}>{child}</Box>
            ))}
          </Box>
        </Box>

      </div>
    </>
  )
}

Carousel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Carousel);
