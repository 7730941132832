import React from 'react';
import { Link } from 'react-router-dom';
import classNames from "classnames";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import Name from '../../atoms/Name';
import CopyNoHTML from '../../atoms/CopyNoHTML';

const styles = (theme) => ({
  slide__link: {
    textDecoration: "none",
  },
  slide__card: {
    backgroundColor: (props) => props.color,
    marginLeft: `-${theme.spacing(2)}px`,
    marginRight: `-${theme.spacing(0)}px`,
    borderRadius: 0,
    width: (props) =>
      props.slide.image_orientation === "portrait" ?
      "96vw" : "96vw",
    [theme.breakpoints.up('sm')]: {
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      width: (props) =>
        props.slide.image_orientation === "portrait" ?
        "21vw" : "32vw",
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
  },
  slide__cardContent: {
    color: (props) => props.color === "#03172E" ?
      theme.palette.common.white : theme.palette.text.primary,
    padding:"0!important",
    "& > *": {
      marginBottom: theme.spacing(3),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      [theme.breakpoints.only('sm')]: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        marginBottom: theme.spacing(4),
      },
      [theme.breakpoints.up('md')]: {
        padding:theme.spacing(0),
        marginBottom: theme.spacing(4),
      },
    },
    "& > h5": {
      fontSize: "1.8rem",
      marginBottom: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing(2.5),
      },
    },
    "& > h2": {
      marginBottom: theme.spacing(0),
    },
    "& h2 a": {textDecoration:"underline"},
    "& a:active":{
      color: (props) => props.color === "#03172E" ?
        theme.palette.common.white : theme.palette.text.primary,
    },
    "& a:hover":{
      color: (props) => props.color === "#03172E" ?
        theme.palette.common.white : theme.palette.text.primary,
    },
    "& a:link":{
      color: (props) => props.color === "#03172E" ?
        theme.palette.common.white : theme.palette.text.primary,
    },
    "& a:visited":{
      color: (props) => props.color === "#03172E" ?
        theme.palette.common.white : theme.palette.text.primary,
    },
  },
  image__cover: {
    position:"relative",
    padding:0,
    paddingLeft:theme.spacing(2),
    paddingRight:theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingLeft:theme.spacing(4),
      paddingRight:theme.spacing(4),
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft:theme.spacing(0),
      paddingRight:theme.spacing(0),
    },
  },
  image__coverImage: {
    width: "100%",
    backgroundImage: (props) => props.slide.image.sizes.large ?
      `url(
        ${props.slide.image.sizes.large}
      )`: "none",
    backgroundPosition: (props) => `${props.slide.image_anchor_point}`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    paddingBottom: (props) =>
      props.slide.image_orientation === "portrait" ?
      "137.78%" : "56.25%",
    [theme.breakpoints.down('sm')]: {
      paddingBottom: (props) =>
        props.slide.image_orientation === "portrait" ?
        "56.25%" : "56.25%",
    },
    [theme.breakpoints.down('xs')]: {
      paddingBottom: (props) =>
        props.slide.image_orientation === "portrait" ?
        "100%" : "100%",
    },
  },
});

function SlideV2(props) {
  const { classes, slide } = props;

  let slug = ""

  if(slide.link_type === "internal"){
    slug = slide.story_link.split('/');
    slug = slug[slug.length -2];
  }

  return (
    <Card elevation={0} className={classNames(classes.slide__card)}>
      <CardContent className={classNames(classes.slide__cardContent)}>
        <div className={classes.image__cover}>
          {slide.link_type === "internal" &&
            <Link to={`/${slug}`} className={classNames(classes.slide__link)}>
              <div className={classNames(classes.image__coverImage)} />
            </Link>
          }
          {slide.link_type === "external" &&
            <a href={slide.story_link_external} target="_blank" rel="noopener noreferrer" className={classNames(classes.slide__link)} >
              <div className={classNames(classes.image__coverImage)} />
            </a>
          }
        </div>
        <Typography variant="h5">
          {slide.link_type === "internal" &&
            <Link to={`/${slug}`} className={classNames(classes.slide__link)}>
              <Name name={slide.name} />
            </Link>
          }
          {slide.link_type === "external" &&
            <a href={slide.story_link_external} target="_blank" rel="noopener noreferrer" className={classNames(classes.slide__link)} >
              <Name name={slide.name} />
            </a>
          }
        </Typography>
        <Typography variant="body1" component="h2">
          <CopyNoHTML copyNoHTML={slide.stand_first} />
          {slide.link_type === "internal" &&
            <Link to={`/${slug}`} className={classNames(classes.slide__link)} >
              {slide.link_text}
            </Link>
          }
          {slide.link_type === "external" &&
            <a href={slide.story_link_external} target="_blank" rel="noopener noreferrer" className={classNames(classes.slide__link)} >
              {slide.link_text}
            </a>
          }.
        </Typography>
      </CardContent>
    </Card>
  );
}

SlideV2.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(SlideV2);
