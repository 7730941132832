import React from 'react';
import classNames from "classnames";
import PropTypes from "prop-types";

import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ScrollTop from '../../atoms/ScrollTop';

import { withStyles } from "@material-ui/core";
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';

import SocialLink from '../../atoms/SocialLink';

const styles = (theme) => ({
  socialList: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: "center",
    marginBottom: theme.spacing(4),
    "& > *": {
      width: "fit-content",
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        marginRight: theme.spacing(1),
      },
      [theme.breakpoints.up('1020')]: {
        marginRight: theme.spacing(2),
      },
      "& > *": {
        minWidth: 0,
      },
    },
    "& > div": {
      marginLeft:"auto",
      marginRight:0,
      marginTop: `-${theme.spacing(0.8)}px`,
      cursor:"pointer",
    },
  },
});

function SocialLinkList(props) {
  const { classes, socialLinks } = props;
  const socialLinkList = socialLinks.map(socialLink =>
    <SocialLink key={socialLink.id} socialLink={socialLink} />
  );
  return (
    <List disablePadding className={classNames(classes.socialList)}>
      <Typography variant="body1" style={{ lineHeight:"1.75rem" }}>Follow us</Typography>
      {socialLinkList}
      <ScrollTop {...props}>
        <Typography variant="body1" style={{ lineHeight:"1.75rem" }}><KeyboardArrowUpIcon /> Back to top</Typography>
      </ScrollTop>
    </List>
  );
}

SocialLinkList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(SocialLinkList);
