import React from 'react';
import PropTypes from "prop-types";
import classNames from "classnames";

import Hidden from '@material-ui/core/Hidden';

import Loader from '../../../assets/Loader.svg';
import LoaderWhite from '../../../assets/LoaderWhite.svg';

import { withStyles } from "@material-ui/core";

const styles = (theme) => ({
  video: {
    width: "100%",
    height: "100%",
    display: "block",
    [theme.breakpoints.down('xs')]: {
      height:"50vh",
      overflow: "hidden",
      objectFit:"cover",
    },
  },
  loader: {
    position:"absolute",
    top:"50vh",
    width:"100%",
    height:"50vh",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    zIndex:theme.zIndex.appBar,
    opacity: 1,
    transition:"opacity 0.5s ease-in",
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up('sm')]: {
      top:0,
      height:"calc((100vw)/(16/9))",
      backgroundColor: theme.palette.common.white,
    },
  },

});

function FullScreenVideo({ theme, classes, video}) {

  function onPlay() {
    setTimeout(function() {
      const loader = document.querySelector(".loader")
      if (loader) {
        loader.style.opacity = 0
        setTimeout(function() {
          loader.style.display = "none"
        }, 1000);
      }
    }, 500);
  }

  return (
    <>
      <video autoPlay loop muted playsInline className={classNames(classes.video)} onPlay={onPlay}>
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
      </video>
      <div className={classNames(classes.loader,"loader")}>
        <Hidden xsDown>
        <img src={Loader} style={{ width:"20%",maxWidth:"100px", }} alt="Loading" />
        </Hidden>
        <Hidden smUp>
          <img src={LoaderWhite} style={{ width:"20%",maxWidth:"100px", }} alt="Loading" />
        </Hidden>
      </div>
    </>
  );
}

FullScreenVideo.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(FullScreenVideo);
