import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import classNames from "classnames";

import Box from '@material-ui/core/Box';

import Hero from '../Hero';
import StickyFeature from '../StickyFeature';
import ImageFeature from '../ImageFeature';
import FullWidthStandFirstFeature from '../FullWidthStandFirstFeature';
import CarouselFeature from '../CarouselFeature';
import CarouselFeatureV2 from '../CarouselFeatureV2';
import ArticleFeature from '../ArticleFeature';
import QAndAFeature from '../QAndAFeature';
import ShopifyPurchase from '../ShopifyPurchase';
import StepFeature from '../StepFeature';
import ProductGrid from '../ProductGrid'
import ProfilesFeature from '../ProfilesFeature'

function Main(props) {
  let { slug } = useParams();
  let cleanSlug = slug;
  if(!slug){
    cleanSlug = "home"
  }
  const [mainState, setMainState] = useState({
    loading: true,
    main: [
      {
        acf: {
          components: [],
        },
      },
    ],
  });
  const loadMain = useCallback(() => {
    setMainState(prevState => {
      return {...prevState, loading: true}
     });
    const apiUrl = `${process.env.REACT_APP_API_URL}//wp-json/wp/v2/pages?slug=${cleanSlug}`;
    axios.get(apiUrl)
      .then((response) => {
        const mainData = response.data;
        setMainState({ loading: false, main: mainData });
      })
      .catch(() => {
        console.log("No data loaded!")
      });
  }, [cleanSlug]);

  useEffect(() => {
    loadMain();
  }, [loadMain]);

  const pageComponents = mainState.main[0].acf.components.map(pageComponent => {
    const pageComponentType = pageComponent.acf_fc_layout;
    if(pageComponentType === "hero") return <Hero key={pageComponent.id} content={pageComponent} />;
    if(pageComponentType === "sticky_feature") return <StickyFeature key={pageComponent.id} content={pageComponent} />;
    if(pageComponentType === "image_feature") return <ImageFeature key={pageComponent.id} content={pageComponent} />;
    if(pageComponentType === "full_width_stand_first_feature") return <FullWidthStandFirstFeature key={pageComponent.id} content={pageComponent} />;
    if(pageComponentType === "carousel_feature") return <CarouselFeature key={pageComponent.id} content={pageComponent} />;
    if(pageComponentType === "carousel_feature_v2") return <CarouselFeatureV2 key={pageComponent.id} content={pageComponent} />;
    if(pageComponentType === "article_feature") return <ArticleFeature key={pageComponent.id} content={pageComponent} />;
    if(pageComponentType === "q_and_a_feature") return <QAndAFeature key={pageComponent.id} content={pageComponent} />;
    if(pageComponentType === "shopify_purchase") return <ShopifyPurchase key={pageComponent.id} content={pageComponent} />;
    if(pageComponentType === "step_feature") return <StepFeature key={pageComponent.id} content={pageComponent} />;
    if(pageComponentType === "product_grid") return <ProductGrid key={pageComponent.id} content={pageComponent} />;
	if(pageComponentType === "profiles") return <ProfilesFeature key={pageComponent.id} content={pageComponent} />;
    return null;
  });

  return (
    <>
      <Box className={classNames("fadeWrapper", mainState.loading ? "hideContent" : "showContent", slug === 'buy-the-book' && "stories", slug === 'stories' && "stories", cleanSlug === 'home' && "home")} />
      { pageComponents }
    </>
  )

}

export default Main;
