import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from "prop-types";
import classNames from "classnames";

import { withStyles } from "@material-ui/core";
import Box from '@material-ui/core/Box';

import BannerText from '../../atoms/BannerText';

const styles = (theme) => ({
  banner: {
    backgroundColor: "#89CCED",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display:"block",
    width:"100%",
    minWidth:"1px",
    textAlign: "center",
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4),
    },
  },
});

function Banner(props) {
  const { classes } = props;

  const [bannerState, setBannerState] = useState({
    loading: true,
    banner: [
      {
        acf: {
          banner: {
            banner_text: "Banner Text",
            buttons: [
              {
                id:"",
                button_type: "",
                button_text: "",
                button_link: "",
              }
            ],
          },
        },
      },
    ],
  });
  const loadBanner = useCallback(() => {
    setBannerState(prevState => {
      return {...prevState, loading: true}
     });
    const apiUrl = `${process.env.REACT_APP_API_URL}//wp-json/wp/v2/header_and_footer`;
    axios.get(apiUrl)
      .then((response) => {
        const bannerData = response.data;
        setBannerState({ loading: false, banner: bannerData });
      })
      .catch(() => {
        console.log("No data loaded!")
      });
  }, []);

  useEffect(() => {
    loadBanner();
  }, [loadBanner]);

  return (
    <Box className={classNames(classes.banner)}>
      <BannerText loading={bannerState.loading} banner={bannerState.banner[0]} />
    </Box>
  );
}

Banner.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Banner);
