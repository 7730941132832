import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import { withStyles } from "@material-ui/core";
import ListItem from '@material-ui/core/ListItem';
import Link from '@material-ui/core/Link';

const styles = (theme) => ({
  divider: {
    borderBottomColor: "#666",
    "&:last-of-type": {
      borderBottom: "0",
    }
  },
  link: {
    backgroundColor:"pink",

  },
});

function HeaderLink(props) {
  const { classes, headerLink: { title, slug, url }, divider, disableGutters, onClick } = props;
  if(!slug) return (
    <ListItem
      disableGutters={disableGutters}
      divider={divider}
      classes={{
        root: classes.divider,
      }}
      onClick={onClick}
      button
    >
      <Link href={url} target="_blank"><div>{title}</div></Link>
    </ListItem>
  );
  return (
    <ListItem
      disableGutters={disableGutters}
      divider={divider}
      classes={{
        root: classes.divider,
      }}
      onClick={onClick}
      button
    >
      <Link component={NavLink} to={`/${slug}`}><div>{title}</div></Link>
    </ListItem>
  );
}

HeaderLink.propTypes = {
  headerLink: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }),
};

export default withStyles(styles, { withTheme: true })(HeaderLink);
