import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import AddIcon from '@material-ui/icons/Add';

import Question from '../../atoms/Question';
import Answer from '../../atoms/Answer';

const Accordion = withStyles( theme => ({
  root: {
    borderTop: `1px solid rgba(3, 23, 46, 0.4)`,
    boxShadow: 'none',
    '&:not(:last-child)': {
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
}))(MuiAccordion);

const AccordionSummary = withStyles( theme => ({
  root: {
    paddingLeft:0,
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    margin: `${theme.spacing(2)}px 0`,
    '&$expanded': {
      margin: `${theme.spacing(2)}px 0`,
    },
  },
  expanded: {},
  expandIcon: {
    color: theme.palette.primary.main,
    '&$expanded': {
      transform: 'rotate(45deg)',
    },
  },
}))(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingLeft:0,
  },
}))(MuiAccordionDetails);

function QAndAItem(props) {
  const { qAndA, onChange, expanded } = props;

  return (
  <Accordion square expanded={expanded === qAndA.id} onChange={onChange(qAndA.id)}>
    <AccordionSummary
    expandIcon={<AddIcon />}
    aria-controls="panel1a-content"
    id="panel1a-header"
    >
      <Typography variant="h4" style={{ paddingTop:"4px" }}>
        <Question question={qAndA.question} />
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Typography variant="body1">
        <Answer answer={qAndA.answer} />
      </Typography>
    </AccordionDetails>
  </Accordion>
  );
}

export default QAndAItem;
