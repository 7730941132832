import React from 'react';
import PropTypes from "prop-types";
import classNames from "classnames";

import { withStyles } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import CopyWithHTML from '../../atoms/CopyWithHTML';

const styles = (theme) => ({
  grid__parent: {
    overflow: "hidden",
  },
  grid: {
    padding: theme.spacing(8),
  },
  grid__item: {
  },
});


function ArticleFeature({ classes, content: {
    body_copy,
    aside,
}}) {

  return (
    <Grid container direction="row-reverse" className={classNames(classes.grid)}>
      <Grid item  xs={12} md={9}>
        <Typography variant="body1" component="div" gutterBottom>
          <CopyWithHTML copyWithHTML={body_copy} />
        </Typography>
      </Grid>
      <Grid item xs={12} md={3}>
        <Typography variant="h4" gutterBottom>
          <CopyWithHTML copyWithHTML={aside} />
        </Typography>
      </Grid>
    </Grid>
  );
}

ArticleFeature.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(ArticleFeature);
