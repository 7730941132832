import React, { useState }  from 'react';
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core";
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';

import QAndAItem from '../../molecules/QAndAItem';
import CopyNoHTML from '../../atoms/CopyNoHTML';
import StepsButton from '../../atoms/StepsButton';

const styles = (theme) => ({
  grid__widthWrapper:{
    padding:theme.spacing(8),
  },
  spacingOR:{
    color: theme.palette.text.primary,
    width: `calc(100% + (${theme.spacing(8)}*2px))`,
    margin: `-${theme.spacing(8)}px`,
    "& > .MuiGrid-item": {
      padding: theme.spacing(8),
    },
    [theme.breakpoints.only('sm')]: {
      "& > .MuiGrid-item": {
        padding: theme.spacing(4),
      },
      "& > .MuiGrid-item:last-of-type": {
        paddingTop: 0,
      },
    },
    [theme.breakpoints.down('xs')]: {
      "& > .MuiGrid-item": {
        padding: `${theme.spacing(6)}px ${theme.spacing(2)}px`,
      },
      "& > .MuiGrid-item:last-of-type": {
        paddingTop: 0,
      },
    },
    [theme.breakpoints.down('md')]: {
      "& ul div:first-of-type": {
        borderTop:0,
      },
    },
  },
});

function QAndAFeature({ theme, classes, content: {
    q_and_a,
    aside,
    button_type,
    button_text,
    button_link,
}}) {
  const [expanded, setExpanded] = useState(0);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const qAndAList = q_and_a.map(q_and_a =>
    <QAndAItem key={q_and_a.id} qAndA={q_and_a} onChange={handleChange} expanded={expanded} />
  );

  return (
    <Box>
      <Container maxWidth="xl" className={classes.grid__widthWrapper}>
        <Grid
          container
          spacing={10}
          direction="row-reverse"
          classes={{ "spacing-xs-10": classes.spacingOR }}
        >
          <Grid item xs={12} md={9}>
            <ul style={{ paddingLeft:0, margin:0 }}>
              {qAndAList}
            </ul>
          </Grid>

          <Hidden smDown>
            <Grid item xs={12} md={3}>
              <Typography variant="h4" style={{ marginBottom: theme.spacing(2) }}>
                <CopyNoHTML copyNoHTML={aside} />
              </Typography>
              <StepsButton
                dark white
                buttonType={button_type}
                buttonText={button_text}
                buttonLink={button_link}
              />
            </Grid>
          </Hidden>

        </Grid>
      </Container>
    </Box>
  );
}

QAndAFeature.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(QAndAFeature);
