import React from 'react';
import PropTypes from "prop-types";
import classNames from "classnames";

import { withStyles } from "@material-ui/core";
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import CopyNoHTML from '../../atoms/CopyNoHTML';
import CopyWithHTML from '../../atoms/CopyWithHTML';
import StepsButton from '../../atoms/StepsButton';

const styles = (theme) => ({
  grid__widthWrapper:{
    padding:theme.spacing(8),
  },
  spacingOR:{
    width: `calc(100% + (${theme.spacing(8)}*2px))`,
    margin: `-${theme.spacing(8)}px`,
    "& > .MuiGrid-item": {
      padding: theme.spacing(8),
    },
    [theme.breakpoints.only('sm')]: {
      "& > .MuiGrid-item": {
        padding: theme.spacing(4),
      },
      "& > .MuiGrid-item:last-of-type": {
        paddingTop: 0,
      },
    },
    [theme.breakpoints.down('xs')]: {
      "& > .MuiGrid-item": {
        padding: `${theme.spacing(6)}px ${theme.spacing(2)}px`,
      },
      "& > .MuiGrid-item:last-of-type": {
        paddingTop: 0,
      },
    },
  },
  grid__textCol: {
    display:"flex",
    alignItems:"center",
  },
  theImageWrapper: {
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.up('md')]: {
      maxWidth: 400,
    },
  },
  theImage: {
    width: "100%",
    paddingBottom: "100%",
    backgroundImage: (props) => props.content.image.url ?
      `url(${props.content.image.url})`: "none",
    backgroundPosition: "center center",
    backgroundSize: "100% auto",
    backgroundRepeat:"no-repeat",
    [theme.breakpoints.only('sm')]: {
      paddingBottom: "56.25%",
    },
    [theme.breakpoints.up('md')]: {
      paddingBottom: "177.78%",
      maxHeight: 800,
    },
  },
  standFirst: {
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(8),
    },
  },
  form: {
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(12),
    },
  },
  input: {
    display: "block",
    marginBottom: theme.spacing(3)
  },
  inputLong: {
    display: "block",
    marginBottom: theme.spacing(3)
  },
  inputLabel: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.body2.fontsize
  },
  thankyou: {
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(12),
    },
  },
  againButton: {
    textDecoration: "underline",
    cursor: "pointer",
  },
});

function ShopifyPurchase({ theme, classes, content: {
    title,
    stand_first,
    body_copy,
    image
}}) {

  const [quantity, setQuantity] = React.useState('1')
  const [note, setNote] = React.useState('Unspecified')
  const cleanNote = encodeURIComponent(note)
  let url = `https://stepscourse.myshopify.com/cart/39686550421695:${quantity}?channel=buy_button&note=${cleanNote}`;

  function handleQuantityChange(e) {
    setQuantity(e.target.value)
  }
  function handleNoteChange(e) {
    setNote(e.target.value)
  }

  const [thankyou, setThankyou] = React.useState(false)
  function handleClick(e) {
    setThankyou(true)
  }
  function handleSecondClick(e) {
    setThankyou(false)
  }

  return (
    <Box>
      <Container maxWidth="xl" className={classes.grid__widthWrapper}>
        <Grid
          container
          spacing={10}
          direction="row-reverse"
          classes={{ "spacing-xs-10": classes.spacingOR }}
        >
          <Grid item xs={12} md={6} className={classes.grid__textCol}>

            <div>
              <Typography variant="subtitle1" component="h2" style={title ? { marginBottom:theme.spacing(2)} : {} }>
                <CopyNoHTML copyNoHTML={title} />
              </Typography>

              <Typography variant="h3" gutterBottom className={classNames(classes.standFirst, "standFirst")}>
                <CopyWithHTML copyWithHTML={stand_first} />
              </Typography>

              {thankyou ?
                <Box className={classNames(classes.thankyou)}>
                  <Typography variant="body1" component="div">
                      Checkout opens in new tab.<br/>
                      <span className={classes.againButton} onClick={handleSecondClick}>Checkout again</span>
                  </Typography>
                </Box>
                :
                <>
                  <Typography variant="body1" component="div" style={{ marginBottom: theme.spacing(4) }}>
                    £15 per book
                  </Typography>
                  <form className={classNames(classes.form)} noValidate autoComplete="off">
                    <TextField
                      size="small"
                      id="quantity"
                      label="Quantity"
                      type="number"
                      defaultValue="1"
                      InputLabelProps={{
                        shrink: true,
                        className: classes.inputLabel,
                      }}
                      className={classes.input}
                      onChange={handleQuantityChange}
                    />
                    <span id="notee">
                      <TextField
                        size="small"
                        id="note"
                        label="If known, please confirm your STEPS Course host/organisation (if you do not know, or have not registered for a STEPS course, please leave blank)"
                        type="text"
                        placeholder="host/organisation"
                        InputLabelProps={{
                          shrink: true,
                          className: classes.inputLabel,
                        }}
                        className={classes.inputLong}
                        onChange={handleNoteChange}
                      />
                    </span>
                    <span onClick={handleClick}>
                      <StepsButton
                        buttonType={"external"}
                        buttonText="Checkout"
                        buttonLink={url}
                        dark white
                      />
                    </span>
                  </form>
                </>
              }

              <Typography variant="body1" component="div">
                <CopyWithHTML copyWithHTML={body_copy} />
              </Typography>


            </div>

          </Grid>

          <Grid item xs={12} md={6}>
            <div className={classNames(classes.theImageWrapper)}>
              <div className={classes.theImage}></div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

ShopifyPurchase.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(ShopifyPurchase);
