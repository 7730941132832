import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import CssBaseline from '@material-ui/core/CssBaseline';
import GlobalStyles from "./GlobalStyles";
import Box from '@material-ui/core/Box';

import Header from './components/organisms/Header';
import Main from './components/organisms/Main';
import Banner from './components/organisms/Banner';
import Footer from './components/organisms/Footer';

// import TemporaryDrawer from './components/atoms/Drawer';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles />
      <div className="App">
        <BrowserRouter>
          <Header />
          {/* <TemporaryDrawer /> */}
          <Box style={{ minHeight:"100%" }}>
            <Switch>
              <Route path="/:slug">
                <Main />
              </Route>
              <Route path="/">
                <Main />
              </Route>
            </Switch>
          </Box>
          <Banner />
          <Footer />
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}

export default App;
