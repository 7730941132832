import React, { useState } from 'react';
import PropTypes from "prop-types";
import classNames from "classnames";

import { withStyles } from "@material-ui/core";
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';

import PlayButton from '../../atoms/PlayButton';

const styles = (theme) => ({
  fsModal: {
    backgroundColor:theme.palette.primary.main,
  },
  modelAppBar: {
    alignItems:"flex-end",
  },
  modelToolBar: {
    paddingRight:theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      paddingRight:theme.spacing(2),
    },
  },
  video__cover: {
    position:"relative",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.up('md')]: {
      maxWidth: 500,
    },
  },
  video__coverImage: {
    width: "100%",
    paddingBottom: "100%",
    backgroundImage: (props) => props.videoCover.sizes.large ?
      `url(
        ${props.videoCover.sizes.large}
      )`: "none",
    backgroundPosition: "center center",
    backgroundSize: "auto 100%",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.only('sm')]: {
      paddingBottom: "56.25%",
      backgroundImage: (props) => props.videoCover.sizes.medium ?
        `url(
          ${props.videoCover.sizes.medium}
        )`: "none",
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 500,
    },
  },
  video__playWrapper: {
    position: "absolute",
    top: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems:"center",
    justifyContent: "center",
  },
  video__playIcon: {
    fontSize: "6rem",
    cursor:"pointer",
  },
  video__close: {
    float:"right",
    marginTop: theme.spacing(0),
    right: theme.spacing(0),
    color: "#fff",
    fontFamily:theme.typography.fontFamily,
    fontSize: theme.typography.body1.fontSize,
    cursor:"pointer",
    textTransform: "capitalize",
  },
  video__wrapperOuter: {
    width:"100%",
    backgroundColor: theme.palette.primary.main,
    position: "relative",
    borderRadius: "1rem",
    outline: 0,
    padding: theme.spacing(0),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(12),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(24),
    },
  },
  video__wrapperInner: {
    padding:"56.25% 0 0 0",
    position:"relative",
  },
  video__item: {
    position:"absolute",
    top:"0",
    left:"0",
    width:"100%",
    height:"100%"
  },
});

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

function Video(props) {
  const { classes, videoTitle, video } = props;
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Box className={classes.video__cover}>
        <div className={classes.video__coverImage}></div>
        <Box className={classes.video__playWrapper}>
          <PlayButton className={classes.video__playIcon} onClick={handleOpen} />
        </Box>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        classes={{ paperFullScreen: classes.fsModal }}
        fullScreen
      >
        <AppBar elevation={0} className={classes.modelAppBar}>
          <Toolbar className={classes.modelToolBar}>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close" className={classNames(classes.video__close)}>
              Close
            </IconButton>
          </Toolbar>
        </AppBar>
        <div style={modalStyle} className={classNames(classes.video__wrapperOuter)}>
          <div className={classNames(classes.video__wrapperInner)}>
            <iframe
              src={video}
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
              className={classNames(classes.video__item)}
              title={videoTitle}
            >
            </iframe>
          </div>
        </div>
      </Dialog>
    </>
  );
}

Video.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Video);
