import React from 'react';
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core";
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import Step from '../../atoms/Step';

const styles = (theme) => ({
  grid__widthWrapper:{
    padding:theme.spacing(8),
  },
  spacingOR:{
    width: `calc(100% + (${theme.spacing(8)}*2px))`,
    margin: `-${theme.spacing(8)}px`,
    "& > .MuiGrid-item": {
      padding: theme.spacing(8),
    },
    [theme.breakpoints.only('sm')]: {
      "& > .MuiGrid-item": {
        padding: theme.spacing(4),
      },
      "& > .MuiGrid-item:last-of-type": {
        paddingTop: 0,
      },
    },
    [theme.breakpoints.down('xs')]: {
      "& > .MuiGrid-item": {
        padding: `${theme.spacing(6)}px ${theme.spacing(2)}px`,
      },
      "& > .MuiGrid-item:last-of-type": {
        paddingTop: 0,
      },
    },
  },
});

function StepFeature({ theme, classes, content: {
    step, number_of_steps
}}) {

  const steps = step.map((step, index) =>
    <Step key={step.id} steps={number_of_steps} step={step} index={index} />
  );

  return (
    <Box>
      <Container maxWidth="xl" className={classes.grid__widthWrapper}>
        <Grid
          container
          spacing={10}
          classes={{ "spacing-xs-10": classes.spacingOR }}
        >
          { steps }
        </Grid>
      </Container>
    </Box>
  );
}

StepFeature.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(StepFeature);
