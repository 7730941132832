import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { withStyles } from "@material-ui/core";

const styles = (theme) => ({
  siteLogo: {
    display: "block",
    height: theme.typography.fontSize*1,
  },
});

function SiteLogo(props) {
  const { classes, onClick, siteLogo: { title, sizes: { medium } } } = props;
  return (
    <>
      <Link to="/" onClick={onClick}>
        <img title={title} alt={title} src={medium} className={classes.siteLogo} />
      </Link>
    </>
  );
}

SiteLogo.propTypes = {
  siteLogo: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
  }),
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(SiteLogo);
